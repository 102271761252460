
import { mapActions } from "vuex";

export default {
  name: "Sidebar",
  props: {
    productSlugs: {
      type: Array,
      default: () => [
        "ceyrek-altin",
        "tam-altin-birlik",
        "1-gram-24-ayar-995-0-kulce-altin",
      ],
    },
  },
  data() {
    return {
      charts: [
        {
          title: "Fiyat Analizi",
          name: "Fiyat",
          route: ["product", this.productSlugs[0]],
          period: "weekly",
          categories: [],
          data: [],
        },
        {
          title: "Fiyat Analizi",
          name: "Fiyat",
          route: ["product", this.productSlugs[1]],
          period: "weekly",
          categories: [],
          data: [],
        },
        {
          title: "Fiyat Analizi",
          name: "Fiyat",
          route: ["product", this.productSlugs[2]],
          period: "weekly",
          categories: [],
          data: [],
        },
      ],
    };
  },
  methods: {
    ...mapActions("graphic", ["getGraphicData"]),
    async loadGraphicData() {
      this.charts.forEach((chart) => {
        this.getGraphicData({
          route: chart.route.join("/"),
          period: chart.period,
          limit: 7,
          onSuccess: (res) => {
            if (res.data?.status) {
              const data = res.data.data;
              chart.title = `${res.data.product.name} Fiyat Analizi`;
              data.forEach((element) => {
                chart.data.push(Math.floor(element[0].price));
                chart.categories.push(element[0].dateElement[chart.period]);
                chart.name = element[0].seller;
              });
            }
          },
          onFinish: () => {},
        });
      });
      this.charts.sort();
    },
  },
  mounted() {
    this.loadGraphicData();
  },
};
