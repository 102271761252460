
const TopSeller = () => import('./TopSeller.vue');

export default {
  name: 'ProductCard',
  components: {
    TopSeller,
  },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    product() {
      return this.products[this.index];
    },
    bestSeller() {
      return this.product.sellers?.[0];
    },
    sellerLogo() {
      const path = '/images/sellers/logo';
      const name = this.bestSeller.marketplace
        ? `${this.bestSeller.marketplace}.png`
        : this.bestSeller.logo;
      return `${path}/${name}`;
    },
  },
};
