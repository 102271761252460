
import { mapGetters } from "vuex";
import ProductCard from "./ProductCard.vue";

export default {
  name: "ProductList",
  components: {
    ProductCard,
  },
  data() {
    return {
      currentTime: new Date().toLocaleTimeString("tr-TR"),
    };
  },
  computed: {
    ...mapGetters({
      categoriesProducts: "category/getAllCategoriesProducts",
    }),
  },
  mounted() {
    this.$nextTick(() => {
      this.listenSocket();
    });
    setInterval(() => {
      this.currentTime = new Date().toLocaleTimeString("tr-TR");
    }, 1000);
  },
  methods: {
    listenSocket() {
      this.$socket.product(this.$store);
    },
  },
};
