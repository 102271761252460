
import ProductList from '../components/home/ProductList.vue';
import Sidebar from '../components/home/sidebar/Index.vue';

const PageLoader = () => import('../components/PageLoader.vue');

export default {
  name: 'IndexPage',
  fetchKey: 'home-page',
  components: {
    ProductList,
    Sidebar,
    PageLoader,
  },
  async fetch() {
    if (process.client) {
      await this.$store.dispatch('category/getList');
    }

    await this.$store.dispatch('product/getList');
  },
  head() {
    const title = 'En Ucuz Altın, Gümüş Çeşitleri ve Canlı Döviz Fiyatları - altinmetre.com';
    const description = 'En ucuz altın ve gümüş satıcılarına ulaşabileceğiniz, canlı döviz kurları ve kripto paraları takip edebileceğiniz yeni nesil platform altinmetre.com';
    const siteUrl = `https://${process.env.APP_DOMAIN}`;
    const logoPath = `${siteUrl}/images/white-logo.png`;

    const link = [
      {
        rel: 'canonical',
        href: siteUrl,
      },
    ];

    const meta = [
      {
        property: 'og:type',
        content: 'website',
      },
      {
        property: 'og:title',
        content: title,
      },
      {
        property: 'og:url',
        content: siteUrl,
      },
      {
        property: 'og:description',
        content: description,
      },
      {
        property: 'og:image',
        content: logoPath,
      },
      { name: 'twitter:card', content: 'summary' },
      { name: 'twitter:site', content: '@altinmetre' },
      { name: 'twitter:title', content: title },
      {
        property: 'twitter:description',
        content: description,
      },
      {
        property: 'twitter:image',
        content: logoPath,
      },
    ];

    return {
      link,
      meta,
    };
  },
  jsonld() {
    const socialMediaList = Object.values(this.$config.social);

    return {
      '@context': 'http://schema.org',
      '@type': 'Organization',
      name: 'Altın Metre',
      alternateName: 'altinmetre.com',
      description: 'En ucuz altın ve gümüş satıcılarına ulaşabileceğiniz, canlı döviz kurları ve kripto paraları takip edebileceğiniz yeni nesil platform altinmetre.com',
      foundingDate: '2023',
      url: this.$config.siteURL,
      logo: `${this.$config.siteURL}/images/white-logo.png`,
      sameAs: [
        this.$config.siteURL,
        ...socialMediaList,
      ],
    };
  },
};
