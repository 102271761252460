
export default {
  name: 'PriceChart',
  props: {
    title: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    categories: {
      type: Array,
      default: () => [],
    },
    seriesData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      options: {
        colors: ['#555ca3'],
        dataLabels: {
          enabled: false,
        },
        chart: {
          id: 'vuechart-example',
          type: 'area',
        },
        xaxis: {
          labels: {
            style: {
              colors: '#fff',
            },
          },
          categories: this.categories,
        },
        yaxis: {
          labels: {
            style: {
              colors: '#fff',
            },
          },
        },
        tooltip: {
          theme: 'dark',
          fillSeriesColor: false,
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 0.5,
            opacityFrom: 1,
            opacityTo: 0.5,
            stops: [0, 50, 100],
            colorStops: [
              [
                {
                  color: '#818cf8',
                  opacity: 0.7,
                },
              ],
            ],
          },
        },
      },
      series: this.seriesData,
    };
  },
};
